import pluralize from 'pluralize'
import { errorToast } from '../utils/error-toast'
import { Framework } from 'store/modules/frameworks'
import { FrameworkState } from '../types/entities'
import { InternalApiClient } from './internal-api-client'
import { openModal } from '../utils/open-modal'
import { promiseToast } from '../utils/promise-toast'
import { Requirement } from 'store/modules/requirements'
import { store } from 'store/index'
import { successToast } from '../utils/success-toast'

type Dependencies = {
  openModal: typeof openModal
}
const defaultDependencies = {
  openModal,
}

type FrameworkOnChangeRequirementLevelProps = {
  frameworkId: string
  newLevel: number
  positionId: string
  requirement?: Requirement | undefined
  skillVariantId: string
}

type FrameworkOnChangeRequirementLevelConfirmationProps = {
  fillRequirements?: boolean
} & FrameworkOnChangeRequirementLevelProps

export class FrameworkService {
  constructor(
    private client: InternalApiClient,
    private deps: Dependencies = defaultDependencies
  ) {}

  onAddPositionForEmptyDiscipline(slug: string) {
    location.assign(`/teams/${slug}`)
  }

  openEditPosition(props: { positionId: string; frameworkId: string }) {
    const { positionId, frameworkId } = props

    this.deps.openModal(
      `/manage_position_modal?framework_id=${frameworkId}&position_id=${positionId}`
    )
  }

  async deletePosition(props: { positionId: string }) {
    const { positionId } = props

    await store.positions.destroy(positionId)

    this.refreshFrameworkPage()
  }

  async changeRequirement(
    props: FrameworkOnChangeRequirementLevelConfirmationProps
  ) {
    const {
      fillRequirements,
      frameworkId,
      newLevel,
      positionId,
      requirement,
      skillVariantId,
    } = props

    try {
      if (!requirement || JSON.stringify(requirement) === '{}') {
        // // if a requirement isn't passed, a new requirement should be created
        const frameworksSkill =
          store.frameworksSkills.forFrameworkAndSkillVariant(
            frameworkId,
            skillVariantId
          )

        if (!frameworksSkill) return

        if (fillRequirements) {
          promiseToast(
            'framework-page-create-requirements',
            this.createRequirements(frameworksSkill.id, newLevel, positionId),
            'Requirements created',
            undefined,
            'Creating requirements..'
          )
        } else {
          this.createRequirement(frameworksSkill.id, newLevel, positionId)
        }
      } else if (requirement.level === newLevel) {
        // if an existing requirement is passed through with the same level, delete it
        await this.deleteRequirement(requirement)
      } else {
        const result = await store.requirements.update(
          requirement.id,
          { level: newLevel },
          { source: 'requirement-card' }
        )

        if (result.success) {
          successToast('Requirement updated')
        } else {
          errorToast()
        }
      }
    } catch (e) {
      return 'Something went wrong, please try again.'
    }
  }

  async deleteRequirement(requirement: Requirement) {
    const result = await requirement.destroy({ source: 'requirement-card' })
    if (result.success) {
      successToast('Requirement removed')
    } else {
      errorToast()
    }
  }

  async changeState(props: { frameworkId: number; state: FrameworkState }) {
    const { frameworkId, state } = props

    return this.client.patch<{ notice: string }>(`/frameworks/${frameworkId}`, {
      framework: { aasm_state: state },
    })
  }

  private refreshFrameworkPage() {
    const frameworkPageUpdatedEvent = new Event('frameworkpage:updated')
    document.dispatchEvent(frameworkPageUpdatedEvent)
  }

  private async createRequirement(
    frameworksSkillId: string,
    level: number,
    positionId: string
  ) {
    const result = await store.requirements.create(
      {
        frameworksSkill: frameworksSkillId,
        level,
        position: positionId,
      },
      {
        include: ['position'],
        source: 'requirement-card',
      }
    )

    if (result.success) {
      successToast('Requirement created')
    } else {
      errorToast()
    }
  }

  private async createRequirements(
    frameworksSkillId: string,
    level: number,
    positionId: string
  ) {
    return await store.requirements.create(
      {
        fillRequirements: true,
        frameworksSkill: frameworksSkillId,
        level,
        position: positionId,
      },
      {
        include: ['frameworks_skill.framework.positions.requirements'],
        source: 'requirement-card',
      }
    )
  }
}
